<template>
    <div class="hsCom3">
        <div class="hc3Box">
            <!-- 左边文字 -->
            <div class="hc3Left">
                <div class="hc3Text wow animate__animated animate__slideInUp">
                    <!-- 顶部大字 -->
                    <div class="hc3TBig">专属风格设计</div>
                    <div class="hc3TBig2">威有打造企业官网</div>
                    <!-- 底部文字 -->
                    <div class="hc3TSmall wow animate__animated animate__slideInUp">
                        <p class="hc3STop">威有科技坚持为企业打造独一无二的企业官网！</p>
                        <p class="hc3SText">
                            围绕CI设计系统，根据色彩气质选择官网主色，构建专属配色方案；
                            提炼企业理念，对官网中的组件元素进行个性化设计，增强官网辨识度；<br />
                            研究企业定位，坚持官网差异化设计，让一百个网站有一百种风格；
                            线框图、原型图、保真交互Demo，官网在设计之初必经多次底稿演示， 确保高质UX用户体验。
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HsCom3',
};
</script>

<style lang="less" scoped>
.hsCom3 {
    min-width: 1423px;
    height: 600px;
    margin-top: 20px;
    background: url('http://iv.vu818.com/img/dcf9739ba9afc5c3b6c9d31dba9daf52_jy2.jpg') center no-repeat;
    .hc3Box {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 左边文字
        .hc3Left {
            width: 600px;
            height: 100%;
            padding-top: 100px;
            position: relative;
            background: url('http://iv.vu818.com/img/56a6f0e9eba3e39d6b4a23b9b4ead93a_jy2.png') center no-repeat;
            .hc3Text {
                width: 450px;
                height: 70%;
                position: absolute;
                top: 90px;
                left: 80px;
                text-align: left;
                // 顶部大字
                .hc3TBig {
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    font-size: 40px;
                    font-weight: 900;
                    border-bottom: 1px solid #eee;
                }
                .hc3TBig2 {
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    font-size: 35px;
                    border-bottom: 1px solid #eee;
                }
                // 底部文字
                .hc3TSmall {
                    width: 100%;
                    height: 300px;
                    margin-top: 10px;
                    .hc3STop {
                        width: 100%;
                        height: 50px;
                        font-size: 20px;
                        font-weight: 900;
                        line-height: 50px;
                    }
                    .hc3SText {
                        width: 100%;
                        height: 200px;
                        margin-top: 20px;
                        font-size: 15px;
                    }
                }
            }
        }
    }
}
</style>
