<template>
    <div class="hsCom5">
        <img src="http://iv.vu818.com/img/ba70800ea74b4dfadc1ec0e19279e982_mod5_01.jpg" alt="" class="hc5Bg" />
        <div class="hc5Box">
            <!-- 左盒子 -->
            <div class="hc5Left wow animate__animated animate__slideInUp">
                <!-- 上盒子 -->
                <div class="hc5Top">
                    <div class="hc5Big">
                        <img src="http://iv.vu818.com/img/hc1142.png" alt="" />
                        <div class="hc5bP">
                            <p>程序QC检测</p>
                            <p>Program QC detection</p>
                        </div>
                    </div>
                    <div class="hc5Small">
                        网站在上线之初必经严格QC检测，由专业软件测试人员， 对200多项程序测试条目逐一排查，严格把控网站质量，确保网站 安全，高质。
                    </div>
                </div>
                <!-- 下盒子 -->
                <div class="hc5Bottom">
                    <div class="hc5Big">
                        <img src="http://iv.vu818.com/img/hc1142.png" alt="" />
                        <div class="hc5bP">
                            <p>Web漏洞检测</p>
                            <p>Web vulnerability detection</p>
                        </div>
                    </div>
                    <div class="hc5Small">
                        对网站漏洞进行扫描检测，及时协助更新漏洞补丁。 对新上线网站开启观察模式，
                        统计安全威胁，并提供网站漏洞修补服务，保证网站能够顺利运营。
                    </div>
                </div>
            </div>
            <!-- 中间 -->
            <div class="hc5Center wow animate__animated animate__slideInUp">
                <p class="hc5CP">威有科技周全部署</p>
                <p class="hc5CBig">
                    网站<br />
                    安全部署
                </p>
                <p class="hc5CP">Secure deployment</p>
                <p class="hc5CP">提供多项技术应对网络安全威胁</p>
                <img src="http://iv.vu818.com/img/hc1141.png" alt="" class="hc5CImg" />
            </div>
            <!-- 右盒子 -->
            <div class="hc5Right wow animate__animated animate__slideInUp">
                <!-- 上盒子 -->
                <div class="hc5Top">
                    <div class="hc5Big">
                        <img src="http://iv.vu818.com/img/hc1142.png" alt="" />
                        <div class="hc5bP">
                            <p>流量防火墙</p>
                            <p>Traffic firewalls</p>
                        </div>
                    </div>
                    <div class="hc5Small">
                        监测实时目标，对已有流量主动进行分析，对满足规则的攻击流量 进行告警和阻止，能够充分保证系统完整。
                        有效实现SQL、命令注入、非法HTTP协议请求等多项安全防护。
                    </div>
                </div>
                <!-- 下盒子 -->
                <div class="hc5Bottom">
                    <div class="hc5Big">
                        <img src="http://iv.vu818.com/img/hc1142.png" alt="" />
                        <div class="hc5bP">
                            <p>网站安全卫士</p>
                            <p>Website security guard</p>
                        </div>
                    </div>
                    <div class="hc5Small">加载第三方安全应用，强化网站安全水准。 高效拦截CC、DNS、流量攻击，让你的网站固若金汤</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HsCom5',
};
</script>

<style lang="less" scoped>
.hsCom5 {
    min-width: 1423px;
    height: 650px;
    margin-top: 20px;
    position: relative;
    // background: url("vu818.com/vu818com/img/images/mod5_01.jpg") no-repeat center;
    .hc5Bg {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
    }
    .hc5Box {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        text-align: left;
        // 左右盒子
        .hc5Left,
        .hc5Right {
            width: 39%;
            height: 100%;
            float: left;
            .hc5Top,
            .hc5Bottom {
                width: 100%;
                height: 49%;
                border-radius: 20px;
                background-color: #fff;
                .hc5Big {
                    width: 80%;
                    height: 60px;
                    margin: 0 auto;
                    padding-top: 20px;
                    img {
                        float: left;
                    }
                    .hc5bP {
                        float: left;
                        // width: 300px;
                        height: 60px;
                        p {
                            line-height: 30px;
                            padding-left: 20px;
                            font-weight: 900;
                            font-size: 20px;
                        }
                    }
                }
                .hc5Small {
                    width: 80%;
                    height: 200px;
                    font-size: 17px;
                    line-height: 30px;
                    margin: 0 auto;
                    margin-top: 40px;
                }
            }
            .hc5Bottom {
                margin-top: 2%;
            }
            .hc5Top:hover {
                transform: translateY(-5px);
                transition: 0.5s;
            }
            .hc5Bottom:hover {
                transform: translateY(-5px);
                transition: 0.5s;
            }
        }
        // 中间盒子
        .hc5Center {
            width: 19%;
            height: 100%;
            float: left;
            background: linear-gradient(to right, #7781f1, #7781f1e0);
            text-align: center;
            border-radius: 20px;
            color: white;
            .hc5CP {
                width: 100%;
                height: 30px;
                line-height: 30px;
                margin-top: 50px;
            }
            .hc5CBig {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-weight: 900;
                font-size: 40px;
            }
            .hc5CImg {
                margin-top: 100px;
            }
        }
    }
}
</style>
