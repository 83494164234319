<template>
    <div class="hsCom6">
        <div class="hc6Box">
            <!-- 左边文字 -->
            <div class="hc6Left">
                <div class="hc6Text wow animate__animated animate__slideInUp">
                    <!-- 顶部大字 -->
                    <div class="hc6TBig">一站式后台管理</div>
                    <div class="hc6TBig2">10分钟上手的后台管理系统， 真的简单好用</div>
                    <!-- 底部文字 -->
                    <div class="hc6TSmall">
                        <p class="hc6SText">
                            威有科技专利网站后台，为网站管理带来福音；<br />
                            仅需简单交互，就能更新官网内容；<br />
                            一站更新，就能实现多个平台同步；<br />
                            实现真正高效管理网站。<br />
                        </p>
                    </div>
                </div>
            </div>
            <!-- 右边图片 -->
            <div class="hc6Right wow animate__animated animate__slideInUp">
                <img src="http://iv.vu818.com/img/hc1151.jpg" alt="" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HsCom6',
};
</script>

<style lang="less" scoped>
.hsCom6 {
    min-width: 1423px;
    height: 500px;
    margin-top: 20px;
    .hc6Box {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 左边文字
        .hc6Left {
            width: 500px;
            height: 100%;
            text-align: left;
            padding-top: 100px;
            position: relative;
            float: left;
            background: url('http://iv.vu818.com/img/5c3afe7241b5a5ba78585c3ddf959b12_mod04_02.png') center no-repeat;
            .hc6Text {
                width: 350px;
                height: 70%;
                position: absolute;
                top: 90px;
                left: 80px;
                color: white;
                // 顶部大字
                .hc6TBig {
                    width: 100%;
                    height: 80px;
                    line-height: 80px;
                    font-size: 40px;
                    font-weight: 900;
                }
                .hc6TBig2 {
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    font-size: 15px;
                    border-bottom: 1px solid #eee;
                }
                // 底部文字
                .hc6TSmall {
                    width: 100%;
                    height: 300px;
                    margin-top: 10px;
                    .hc6SText {
                        width: 100%;
                        height: 200px;
                        margin-top: 20px;
                        font-size: 15px;
                    }
                }
            }
        }
        // 右边图片
        .hc6Right {
            float: right;
            margin-top: 100px;
        }
    }
}
</style>
