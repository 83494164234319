<template>
    <div class="hsCom2">
        <div class="hc2Box">
            <!-- 标题 -->
            <div class="hc2Title wow animate__animated animate__slideInUp">
                <p class="hc2Big">威有网站建设服务</p>
                <p class="hc2Small">MARKETING SERVICES</p>
            </div>
            <!-- 图标列表 -->
            <div class="hc2List wow animate__animated animate__slideInUp">
                <ul>
                    <li class="hc2Li">
                        <div class="hc2LIcon">
                            <img src="http://iv.vu818.com/img/hc1121.png" alt="" />
                        </div>
                        <div class="hc2LText">自由个性化的网站定制服务</div>
                    </li>
                    <li class="hc2Li">
                        <div class="hc2LIcon">
                            <img src="http://iv.vu818.com/img/hc1122.png" alt="" />
                        </div>
                        <div class="hc2LText">一对一 专人专项协作策划网站</div>
                    </li>
                    <li class="hc2Li">
                        <div class="hc2LIcon">
                            <img src="http://iv.vu818.com/img/hc1123.png" alt="" />
                        </div>
                        <div class="hc2LText">售后提供多项免费维护</div>
                    </li>
                    <li class="hc2Li">
                        <div class="hc2LIcon">
                            <img src="http://iv.vu818.com/img/hc1124.png" alt="" />
                        </div>
                        <div class="hc2LText">支持PC+APP+WAP+微信四合一网站开发</div>
                    </li>
                    <li class="hc2Li">
                        <div class="hc2LIcon">
                            <img src="http://iv.vu818.com/img/hc1125.png" alt="" />
                        </div>
                        <div class="hc2LText">支持ASP、PHP、.Net等多种语言开发</div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HsCom2',
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: 'wow',
            animateClass: 'animate__animated',
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.hsCom2 {
    min-width: 1423px;
    height: 500px;
    margin-top: 20px;
    .hc2Box {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 标题
        .hc2Title {
            width: 50%;
            height: 80px;
            margin: 0 auto;
            .hc2Big {
                width: 100%;
                height: 50px;
                line-height: 50px;
                text-align: center;
                font-size: 40px;
                font-weight: 900;
            }
            .hc2Small {
                width: 100%;
                height: 30px;
                font-size: 20px;
                line-height: 30px;
                text-align: center;
            }
        }
        // 图标列表
        .hc2List {
            width: 100%;
            height: 300px;
            margin-top: 50px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                .hc2Li {
                    width: 19%;
                    height: 100%;
                    text-align: center;
                    .hc2LIcon {
                        width: 150px;
                        height: 50%;
                        border-radius: 50%;
                        margin: 0 auto;
                        margin-top: 20px;
                        border: 1px solid white;
                        box-shadow: 1px 1px 10px 1px gainsboro;
                        transition: 0.5s;
                        img {
                            width: 40%;
                            height: 40%;
                            margin-top: 50px;
                        }
                    }
                    .hc2LText {
                        width: 200px;
                        height: 50px;
                        margin: 0 auto;
                        margin-top: 30px;
                        font-size: 18px;
                    }
                }
                .hc2Li:hover {
                    .hc2LIcon {
                        transform: rotate(360deg);
                    }
                }
            }
        }
    }
}
</style>
