<template>
    <div class="hsCom8">
        <div class="hc8Box">
            <!-- 左侧大图 -->
            <div class="hc8Left">
                <div class="hlBox" :class="{ theBc: isActive === item }" @mousemove="theBcs(item)" v-for="item in 3" :key="item">
                    <p class="hlBig">网站栏目架构</p>
                    <div class="hlSmall">
                        <p class="hsP">GROUP</p>
                        <p class="hsP">OFFICIAL</p>
                        <p class="hsP">WEBSITE</p>
                    </div>
                    <img src="http://iv.vu818.com/img/hc1151.png" alt="" class="hlImg" />
                </div>
            </div>
            <!-- 右侧架构 -->
            <div class="hc8Right" v-show="showR === 1">
                <!-- 上部分 -->
                <div class="hrTop">
                    <ul>
                        <li class="hLi3">
                            <div class="hl1">首页</div>
                            <img src="http://iv.vu818.com/img/sm3-2.png" alt="" class="hl2Img" />
                            <div class="hl3">
                                <p class="h3p">Banner</p>
                                <p class="h3p">动态信息展示</p>
                                <p class="h3p">项目案例</p>
                            </div>
                        </li>
                        <li class="hLi5">
                            <div class="hl1">关于我们</div>
                            <img src="http://iv.vu818.com/img/sm5.png" alt="" class="hl2Img" />
                            <div class="hl3">
                                <p class="h3p">企业概况</p>
                                <p class="h3p">发展历程</p>
                                <p class="h3p">企业荣誉</p>
                                <p class="h3p">资格证书</p>
                                <p class="h3p">企业团队</p>
                            </div>
                        </li>
                        <li class="hLi4">
                            <div class="hl1">新闻中心</div>
                            <img src="http://iv.vu818.com/img/sm4.png" alt="" class="hl2Img" />
                            <div class="hl3">
                                <p class="h3p">新闻动态</p>
                                <p class="h3p">视频中心</p>
                                <p class="h3p">专题报告</p>
                                <p class="h3p">媒体报告</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <!-- 下部分 -->
                <div class="hrBottom">
                    <ul>
                        <li class="hLi4">
                            <div class="hl1">产品中心</div>
                            <img src="http://iv.vu818.com/img/sm4.png" alt="" class="hl2Img" />
                            <div class="hl3">
                                <p class="h3p">产品视图</p>
                                <p class="h3p">产品介绍</p>
                                <p class="h3p">在线订购</p>
                                <p class="h3p">...</p>
                            </div>
                        </li>
                        <li class="hLi4">
                            <div class="hl1">项目案例</div>
                            <img src="http://iv.vu818.com/img/sm4.png" alt="" class="hl2Img" />
                            <div class="hl3">
                                <p class="h3p">最新案例</p>
                                <p class="h3p">全部案例</p>
                                <p class="h3p">案例分类</p>
                                <p class="h3p">...</p>
                            </div>
                        </li>
                        <li class="hLi4">
                            <div class="hl1">联系我们</div>
                            <img src="http://iv.vu818.com/img/sm4.png" alt="" class="hl2Img" />
                            <div class="hl3">
                                <p class="h3p">人才招聘</p>
                                <p class="h3p">联系方式</p>
                                <p class="h3p">分公司介绍</p>
                                <p class="h3p">...</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="hc8Right" v-show="showR === 2">
                <!-- 上部分 -->
                <div class="hrTop">
                    <ul>
                        <li class="hLi3">
                            <div class="hl1">首页</div>
                            <img src="http://iv.vu818.com/img/sm3-2.png" alt="" class="hl2Img" />
                            <div class="hl3">
                                <p class="h3p">Banner</p>
                                <p class="h3p">动态信息展示</p>
                                <p class="h3p">项目案例</p>
                            </div>
                        </li>
                        <li class="hLi5">
                            <div class="hl1">关于我们</div>
                            <img src="http://iv.vu818.com/img/sm5.png" alt="" class="hl2Img" />
                            <div class="hl3">
                                <p class="h3p">企业概况</p>
                                <p class="h3p">发展历程</p>
                                <p class="h3p">企业荣誉</p>
                                <p class="h3p">资格证书</p>
                                <p class="h3p">企业团队</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <!-- 下部分 -->
                <div class="hrBottom">
                    <ul>
                        <li class="hLi4">
                            <div class="hl1">产品中心</div>
                            <img src="http://iv.vu818.com/img/sm4.png" alt="" class="hl2Img" />
                            <div class="hl3">
                                <p class="h3p">产品视图</p>
                                <p class="h3p">产品介绍</p>
                                <p class="h3p">在线订购</p>
                                <p class="h3p">...</p>
                            </div>
                        </li>
                        <li class="hLi4">
                            <div class="hl1">项目案例</div>
                            <img src="http://iv.vu818.com/img/sm4.png" alt="" class="hl2Img" />
                            <div class="hl3">
                                <p class="h3p">最新案例</p>
                                <p class="h3p">全部案例</p>
                                <p class="h3p">案例分类</p>
                                <p class="h3p">...</p>
                            </div>
                        </li>
                        <li class="hLi4">
                            <div class="hl1">联系我们</div>
                            <img src="http://iv.vu818.com/img/sm4.png" alt="" class="hl2Img" />
                            <div class="hl3">
                                <p class="h3p">人才招聘</p>
                                <p class="h3p">联系方式</p>
                                <p class="h3p">分公司介绍</p>
                                <p class="h3p">...</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="hc8Right" v-show="showR === 3">
                <!-- 上部分 -->
                <div class="hrTop">
                    <ul>
                        <li class="hLi3">
                            <div class="hl1">首页</div>
                            <img src="http://iv.vu818.com/img/sm3-2.png" alt="" class="hl2Img" />
                            <div class="hl3">
                                <p class="h3p">Banner</p>
                                <p class="h3p">动态信息展示</p>
                                <p class="h3p">项目案例</p>
                            </div>
                        </li>
                        <li class="hLi5">
                            <div class="hl1">关于我们</div>
                            <img src="http://iv.vu818.com/img/sm5.png" alt="" class="hl2Img" />
                            <div class="hl3">
                                <p class="h3p">企业概况</p>
                                <p class="h3p">发展历程</p>
                                <p class="h3p">企业荣誉</p>
                                <p class="h3p">资格证书</p>
                                <p class="h3p">企业团队</p>
                            </div>
                        </li>
                        <li class="hLi4">
                            <div class="hl1">新闻中心</div>
                            <img src="http://iv.vu818.com/img/sm4.png" alt="" class="hl2Img" />
                            <div class="hl3">
                                <p class="h3p">新闻动态</p>
                                <p class="h3p">视频中心</p>
                                <p class="h3p">专题报告</p>
                                <p class="h3p">媒体报告</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <!-- 下部分 -->
                <div class="hrBottom">
                    <ul>
                        <li class="hLi4">
                            <div class="hl1">产品中心</div>
                            <img src="http://iv.vu818.com/img/sm4.png" alt="" class="hl2Img" />
                            <div class="hl3">
                                <p class="h3p">产品视图</p>
                                <p class="h3p">产品介绍</p>
                                <p class="h3p">在线订购</p>
                                <p class="h3p">...</p>
                            </div>
                        </li>
                        <li class="hLi4">
                            <div class="hl1">联系我们</div>
                            <img src="http://iv.vu818.com/img/sm4.png" alt="" class="hl2Img" />
                            <div class="hl3">
                                <p class="h3p">人才招聘</p>
                                <p class="h3p">联系方式</p>
                                <p class="h3p">分公司介绍</p>
                                <p class="h3p">...</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HsCom8',
    data() {
        return {
            isActive: 1,
            showR: 1,
        };
    },
    methods: {
        theBcs(i) {
            this.isActive = i;
            this.showR = i;
        },
    },
};
</script>

<style lang="less" scoped>
.hsCom8 {
    min-width: 1423px;
    height: 500px;
    background: url('http://iv.vu818.com/img/w_bg3.jpg');
    overflow: hidden;
    .hc8Box {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 左侧大图
        .hc8Left {
            width: 300px;
            height: 100%;
            float: left;
            .hlBox {
                width: 285px;
                height: 112px;
                border-radius: 20px;
                float: left;
                margin-top: 40px;
                background-color: #273139;
                transition: 0.5s;
                cursor: pointer;
                .hlBig {
                    width: 90%;
                    height: 52px;
                    line-height: 52px;
                    font-size: 22px;
                    font-weight: 900;
                    color: white;
                    margin: 0 auto;
                }
                .hlSmall {
                    width: 40%;
                    min-height: 40px;
                    // margin-top: 10px;
                    float: left;
                    .hsP {
                        width: 100%;
                        height: 20px;
                        line-height: 20px;
                        text-align: center;
                        color: white;
                        font-size: 12px;
                    }
                }
                .hlImg {
                    width: 90px;
                    height: 56px;
                    margin-top: 4px;
                    margin-left: 81px;
                    float: left;
                    border-bottom-right-radius: 20px;
                }
            }
            .theBc {
                background-color: #ffae4e;
            }
        }
        // 右侧架构
        .hc8Right {
            width: 950px;
            height: 100%;
            float: left;
            margin-left: 20px;
            // 上部分
            .hrTop,
            .hrBottom {
                width: 100%;
                height: 45%;
                margin-top: 25px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    .hLi3 {
                        width: 300px;
                        height: 100%;
                        .hl1 {
                            width: 110px;
                            height: 40px;
                            padding-left: 35px;
                            padding-top: 5px;
                            box-sizing: border-box;
                            margin-top: 90px;
                            color: white;
                            float: left;
                            // background: url('http://iv.vu818.com/img/dn1.png') no-repeat;
                        }
                        .hl2Img {
                            margin-top: 45px;
                            float: left;
                        }
                        .hl3 {
                            width: 130px;
                            height: 100%;
                            float: left;
                            margin-left: 10px;
                            .h3p {
                                width: 100%;
                                height: 30px;
                                line-height: 30px;
                                margin-top: 32px;
                                color: white;
                                font-size: 15px;
                            }
                        }
                    }
                    .hLi5 {
                        width: 300px;
                        height: 100%;
                        .hl1 {
                            width: 110px;
                            height: 40px;
                            padding-left: 20px;
                            padding-top: 5px;
                            box-sizing: border-box;
                            margin-top: 90px;
                            color: white;
                            float: left;
                            // background: url('http://iv.vu818.com/img/dn1.png') no-repeat;
                        }
                        .hl2Img {
                            margin-top: 48px;
                            float: left;
                        }
                        .hl3 {
                            width: 130px;
                            height: 90%;
                            float: left;
                            margin-left: 10px;
                            margin-top: 20px;
                            .h3p {
                                width: 100%;
                                height: 30px;
                                line-height: 30px;
                                margin-top: 12px;
                                color: white;
                                font-size: 15px;
                            }
                        }
                    }
                    .hLi4 {
                        width: 300px;
                        height: 100%;
                        .hl1 {
                            width: 110px;
                            height: 40px;
                            padding-left: 20px;
                            padding-top: 5px;
                            box-sizing: border-box;
                            margin-top: 90px;
                            color: white;
                            float: left;
                            // background: url('http://iv.vu818.com/img/dn1.png') no-repeat;
                        }
                        .hl2Img {
                            margin-top: 48px;
                            float: left;
                        }
                        .hl3 {
                            width: 130px;
                            height: 90%;
                            float: left;
                            margin-left: 10px;
                            margin-top: 20px;
                            .h3p {
                                width: 100%;
                                height: 30px;
                                line-height: 30px;
                                margin-top: 12px;
                                color: white;
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
